<template>
    <div class="box">
        账号密码
    </div>
</template>

<script>
export default {
    name:'account-password'
}
</script>

<style lang="scss" scoped>

</style>